let commons = require('../utils/commons');

function videoHolderInit(videoHolderNode) {
    // elements
    this.baseNode = videoHolderNode;
    this.previewNode = this.baseNode.getElementsByClassName('preview')[0];
    if (this.previewNode === undefined || !this.previewNode) {
        console.log('videoHolderInit: no previewNode found by class "preview"');
        return false;
    }
    this.videoContainerNode = this.baseNode.getElementsByClassName('video-container')[0];
    if (this.previewNode === undefined || !this.previewNode) {
        console.log('videoHolderInit: no videoContainerNode found by class "video-container"');
        return false;
    }
    this.videoLinkNode = this.baseNode.getElementsByClassName('video-link')[0];
    if (this.previewNode === undefined || !this.previewNode) {
        console.log('videoHolderInit: no videoLinkNode found by class "video-link"');
        return false;
    }
    // actions
    this.displayVideo = function() {
        commons.removeClassName(this.videoContainerNode, 'hidden');
        commons.addClassName(this.previewNode, 'hidden');
    }
    // events
    this.videoLinkNode.addEventListener('click', function(e) {
        e.preventDefault();
        this.displayVideo();
    }.bind(this));
}

document.addEventListener('DOMContentLoaded', function(){
    const videoHolderNodes = document.getElementsByClassName('video-holder');
    for(let i = 0; i < videoHolderNodes.length; i++) {
       let videoHolderNode = videoHolderNodes.item(i);
       const videoHolderObj = new videoHolderInit(videoHolderNode);
    }
});
