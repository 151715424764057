import indexStyles from '../sass/common.scss';
let commons = require('./utils/commons');
import facilitiesModalJS from './includes/facilities_modal.js';
import centerSliderJS from './includes/center_slider.js';
import certificatesModalJS from './includes/certificates_modal.js';
import videoHolderJS from './includes/video_holder.js';
import sectionHeadingBkgImg from '../img/section-heading-bkg.jpg';
import sectionHeading2BkgImg from '../img/section-heading-2-bkg.jpg';
import sectionHeading3BkgImg from '../img/section-heading-3-bkg.jpg';
import sectionHeading4BkgImg from '../img/section-heading-4-bkg.jpg';
import sectionHeading5BkgImg from '../img/section-heading-5-bkg.jpg';
import facilitiesList1Img from '../img/facilities-list-1.jpg';
import facilitiesList2Img from '../img/facilities-list-2.jpg';
import facilitiesList3Img from '../img/facilities-list-3.jpg';
import facilitiesList4Img from '../img/facilities-list-4.jpg';
import facilitiesList5Img from '../img/facilities-list-5.jpg';
import facilitiesList6Img from '../img/facilities-list-6.jpg';
import facilitiesList7Img from '../img/facilities-list-7.jpg';
import facilitiesList8Img from '../img/facilities-list-8.jpg';
import facilitiesList9Img from '../img/facilities-list-9.jpg';
import sectionHeadingContactsBkgImg from '../img/section-heading-contacts-bkg.jpg';
import mapImg from '../img/map.jpg';
import mapPointImg from '../img/map-point.png';
import mailIconYellowImg from '../img/mail-icon-yellow.png';
import addressIconYellowImg from '../img/address-icon-yellow.png';
import phoneIconYellowImg from '../img/phone-icon-yellow.png';
import blogList1Img from '../img/blog-list-1.jpg';
import blogDetail1Img from '../img/blog-detail-1.jpg';
import calendarIconImg from '../img/calendar-icon.png';
import listDubleShevronImg from '../img/list-duble-shevron.png';
import categoryIconImg from '../img/category-icon.png';
import deliveryGroups1Img from '../img/delivery-groups-1.jpg';
import deliveryGroups2Img from '../img/delivery-groups-2.jpg';
import delivery1Img from '../img/delivery-1.png';
import delivery2Img from '../img/delivery-2.png';
import delivery3Img from '../img/delivery-3.png';
import delivery4Img from '../img/delivery-4.png';
import deliveryHeightIconImg from '../img/delivery-height-icon.png';
import deliveryWidthIconImg from '../img/delivery-width-icon.png';
import deliveryLongIconImg from '../img/delivery-long-icon.png';
import deliveryWeightIconImg from '../img/delivery-weight-icon.png';
import deliveryPackagingImg from '../img/delivery-packaging.jpg';
import deliverySlider1Img from '../img/delivery-slider-1.jpg';
import deliverySlider2Img from '../img/delivery-slider-2.jpg';
import deliverySlider3Img from '../img/delivery-slider-3.jpg';
import deliverySlider4Img from '../img/delivery-slider-4.jpg';
import techDepartment1Img from '../img/tech-department-1.jpg';
import techDepartment2Img from '../img/tech-department-2.jpg';
import shevronLeftYellowImg from '../img/shevron-left-yellow.png';
import techParametersTable1Img from '../img/tech-parameters-table-1.png';
import techParametersTable2Img from '../img/tech-parameters-table-2.png';
import techParametersTable3Img from '../img/tech-parameters-table-3.png';
import techParametersTable4Img from '../img/tech-parameters-table-4.png';
import techParametersTable5Img from '../img/tech-parameters-table-5.png';
import techParametersTable6Img from '../img/tech-parameters-table-6.png';
import techParametersTable7Img from '../img/tech-parameters-table-7.png';
import techParametersTable8Img from '../img/tech-parameters-table-8.png';
import techParametersTable9Img from '../img/tech-parameters-table-9.png';
import techParametersTable10Img from '../img/tech-parameters-table-10.png';
import techParametersTable11Img from '../img/tech-parameters-table-11.png';
import techParametersTable12Img from '../img/tech-parameters-table-12.png';
import techParametersTable13Img from '../img/tech-parameters-table-13.png';
import techParametersTable14Img from '../img/tech-parameters-table-14.png';
import techParametersTable15Img from '../img/tech-parameters-table-15.png';
import certificate1Img from '../img/certificate-1.jpg';
import certificate2Img from '../img/certificate-2.jpg';
import certificate3Img from '../img/certificate-3.jpg';
import certificate4Img from '../img/certificate-4.jpg';
import certificate5Img from '../img/certificate-5.jpg';
import certificate6Img from '../img/certificate-6.jpg';
import certificate7Img from '../img/certificate-7.jpg';
import certificate8Img from '../img/certificate-8.jpg';
import certificate9Img from '../img/certificate-9.jpg';
import certificate10Img from '../img/certificate-10.jpg';
import certificate11Img from '../img/certificate-11.jpg';
import certificate12Img from '../img/certificate-12.jpg';
import certificate13Img from '../img/certificate-13.jpg';
import certificate14Img from '../img/certificate-14.jpg';
import certificate15Img from '../img/certificate-15.jpg';
import production1Img from '../img/production-1.jpg';
import production2Img from '../img/production-2.jpg';
import production3Img from '../img/production-3.jpg';
import production4Img from '../img/production-4.png';
import production5Img from '../img/production-5.jpg';
import production6Img from '../img/production-6.jpg';
import productionIcon1Img from '../img/production-icon-1.png';
import productionIcon2Img from '../img/production-icon-2.png';
import productionPlayImg from '../img/production-play.png';
