var popupS = require('popups');
import popupSBasicStyles from "../../../node_modules/popups/css/popupS.min.css";

function certificatesModalInit(certificatesButton) {
    if (certificatesButton === undefined || !certificatesButton) {
        console.log('certificatesModalInit: no valid certificatesButton specified', certificatesButton);
        return false;
    }
    let certificatesID = certificatesButton.getAttribute("href");
    certificatesID = certificatesID.substring(1); // remove #
    const certificatesModalContent =  document.getElementById(certificatesID);
    if (certificatesModalContent === undefined || !certificatesModalContent) {
        console.log('certificatesModalInit: no certificatesModalContent found by id ', certificatesID);
        return false;
    }
    certificatesButton.addEventListener('click', function(e) {
        e.preventDefault();
        popupS.modal({
            content: certificatesModalContent,
            additionalCloseBtnClass: 'certificates-modal-close',
            additionalBaseClass: 'certificates-modal-base',
            additionalPopupClass: 'certificates-modal-popup',
            additionalOverlayClass: 'certificates-modal-overlay',         // classNames, that gets appended to the overlay
            closeBtn: '<span class="close"><img src="img/close.png" alt="close icon"/></span>'
        });
    });
}

document.addEventListener('DOMContentLoaded', function(){
    const certificatesButtons = document.getElementsByClassName('certificates-modal-init');
    for(let i = 0; i < certificatesButtons.length; i++) {
        let certificatesButton = certificatesButtons.item(i);
        certificatesModalInit(certificatesButton);
    }
});
